// Copied from CAL docs for autosign in: https://dev.azure.com/chevron/ITC-APES-Components/_git/CAL-Angular

import { Component, OnInit } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { CvxClaimsPrincipal } from '@cvx/cal-angular';
import { MyMonitoringService } from './services/logging.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedIn = false;

  constructor(
    private authService: CalAngularService,
    private myMonitoringService: MyMonitoringService
  ) { }

  ngOnInit() {
    this.checkAccount();
  }

  checkAccount() {
    this.authService.isUserSignedIn().subscribe({
      next: (value: boolean) => {
        this.loggedIn = value;
        console.log("loggedIn: " + this.loggedIn);
        if (!value) {
          this.login();
        }
        this.myMonitoringService.logAuthUser(this.authService.cvxClaimsPrincipal.email);
        console.log(this.authService.cvxClaimsPrincipal);
        this.myMonitoringService.logPageView("LoginPage");
      },
      error: (error) => {
        console.error("Error checking account", error);
      }
    });
  }

  async login() {
    try {
      const user: CvxClaimsPrincipal = await firstValueFrom(this.authService.userInitiatedSignIn()) as CvxClaimsPrincipal;
      this.authService.isUserSignedIn().subscribe({
        next: (value: boolean) => {
          this.loggedIn = value;
          console.log(this.authService.cvxClaimsPrincipal);
        },
        error: (error) => {
          this.loggedIn = false;
          console.error("Error during login", error);
        }
      });
    } catch (error) {
      this.loggedIn = false;
      console.error("Error during login", error);
    }
  }

  async logout() {
    try {
      const value: boolean = await firstValueFrom(this.authService.userInitiatedSignOut());
      this.loggedIn = value;
      this.myMonitoringService.clearAuthUser();
    } catch (error) {
      console.error("Error during logout", error);
    }
  }
}