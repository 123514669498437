//import { Config } from '@cvx/cal-angular/lib/cal-angular.configuration'
import { Config } from '@cvx/cal-angular'
import { BU } from 'src/app/interfaces/business-unit'

const calConfig: Config = {
  autoSignIn: true,
  popupForLogin: false,
  instance: "https://login.microsoftonline.com/",
  tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  clientId: "15c8dc48-1bb5-449c-b742-df33ff03c3e3",
  redirectUri: "https://costassurance-dev.azure.chevron.com/cost-compare",
  oidcScopes: ["openid", "profile", "email", "offline_access"],
  graphScopes: ["User.Read", "Group.Read.All"],
  cacheLocation: 'localStorage'
}

const AADGroupNames: { BU: BU, GroupName: string}[] =
[
  {BU: BU.MCBU, GroupName: "MCBU-APP-CostAssurance-UAT-Users"},
  {BU: BU.GOMBU, GroupName: "GOMBU-APP-CostAssurance-UAT-Users"},
  {BU: BU.RBU, GroupName: "RBU-APP-CostAssurance-UAT-Users"},
  {BU: BU.CEMREC, GroupName: "CEMREC-APP-CostAssurance-UAT-Users"},
  {BU: BU.LABU, GroupName: "LABU-APP-CostAssurance-UAT-Users"}
]

export const environment = {
  calConfig: calConfig,
  production: false,
  appInsightsKey: '7ecb6dc0-e7b4-4a78-a689-89f69e91bdc9',
  apiCostCompareUrl: 'https://costas-funcapp-dev-cvx.azurewebsites.net/api/CostasApi?code=IjSWAGpNy0vSGoPc6xkXtuHO3SPN1GLf6j24PrYzCaxXjbliNWHf4g==&service=wellcosts',
  apiCostCategoriesUrl: 'https://costas-funcapp-dev-cvx.azurewebsites.net/api/CostasApi?code=IjSWAGpNy0vSGoPc6xkXtuHO3SPN1GLf6j24PrYzCaxXjbliNWHf4g==',
  apiWellcostsUrl: 'https://costas-funcapp-dev-cvx.azurewebsites.net/api/wellcosts',
  functionAppCode: "d8AjPphr/Stww9yQIoKC6ICEtkD9FDS8qCAFNNrjIh0P954l1T04vQ==",
  AADGroupNames: AADGroupNames,
  CostasApiPermission: "https://chevron.onmicrosoft.com/costassurance/user_impersonation",
  pbiReportGOM:"https://app.powerbi.com/groups/me/apps/eefea682-cfb8-4040-9266-45538df33197/reports/bcca044e-eb9c-428c-bec1-6bed40af9c2b/ReportSectionc8c003c0a52ca60ed953?ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26&refreshAccessToken=true",
  pbiReportMCBU:"https://app.powerbi.com/Redirect?action=OpenApp&appId=7247429e-6982-4ce9-b14a-5ad892bc80c2&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  pbiReportRBU:"https://app.powerbi.com/Redirect?action=OpenApp&appId=5c28e1ef-db6c-4aff-a5b7-25f037aa220d&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  pbiReportCEMREC:"https://app.powerbi.com/Redirect?action=OpenApp&appId=b0679f4a-ed4d-4fc4-80ad-3332cc0d48b4&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
  pbiReportLABU:"https://app.powerbi.com/Redirect?action=OpenApp&appId=4c12c2a5-d396-4eb7-93b4-62439b046bd1&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"}
