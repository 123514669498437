import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap, catchError, map, concatMap } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
import { IWells, IWellsPageContainer } from '../interfaces/wells.interface';
import { WellError } from './wellError';
import { ICostCompare } from '../interfaces/cost-compare';
import { environment } from 'src/environments/environment';
import { CostCategory, SelectedWell, SelectedVendor, invoice, invoiceFilter, DataRefresh, UserFavorite, costCategoryPageContainer } from '../interfaces/cost-category';
import { CalAngularService } from '@cvx/cal-angular';
import { BusinessUnitService } from './business-unit.service';

const headers = new HttpHeaders().append('Content-Type', 'application/json').append('Access-Control-Allow-Headers', 'Content-Type').append('Access-Control-Allow-Methods', 'GET, POST').append('Access-Control-Allow-Origin', '*');


@Injectable({
  providedIn: 'root'
})
export class WellsService {
  private costCostCategoriesUrl = environment.apiCostCategoriesUrl;
  public selectedWells: IWells[];
  public selectedWellIDs: string;
  public selectedFilters: any = {};
  public selectedVendors: SelectedVendor[];
  public costCategoryList: CostCategory[];
  public selectedWellApiData: SelectedWell[];
  public invoicefilters: invoiceFilter;
  public dataRefresh: DataRefresh[];
  constructor(private http: HttpClient, private authService: CalAngularService, private buService: BusinessUnitService) {
  }

  private getTokenHeader(): Observable<string> {
    let scopes = [environment.CostasApiPermission];
    let tokenCall = from(this.authService.getAADToken(scopes));
    return tokenCall as Observable<string>;
  }


  getWells(): Observable<IWellsPageContainer | WellError> {
    let apiURL = this.buService.attachBUParamtertoApiURL(environment.apiWellcostsUrl+"?getAll=true&code="+environment.functionAppCode);
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.get<IWellsPageContainer>(apiURL, { headers: headers.append("authorization", `Bearer ${aadToken}`) })
          .pipe(
            catchError(err => this.handleHttpError(err))
          );
      })); 
    
  }

  getCostCompare(): Observable<ICostCompare[] | WellError> {
    return this.http.get<ICostCompare[]>('../../assets/api/cost-categories.json');
  }

  getCostCategories(): Observable<costCategoryPageContainer | WellError> {
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.get<costCategoryPageContainer>(`${environment.apiWellcostsUrl}/${this.selectedWellApiData[0].wellID}/costcategories?afeNumber=${this.selectedWellApiData[0].afeNumber}&jobStart=${this.selectedWellApiData[0].jobStart}&getAll=true&code=${environment.functionAppCode}`, { headers: headers.append("authorization", `Bearer ${aadToken}`) })
        .pipe(
          tap(data => {
            this.costCategoryList = data.costCategories;
          }),
          catchError(err => this.handleHttpError(err))
        );
    }))
  }

  getInvoices(): Observable<invoice[] | WellError> {
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.post<invoice[]>(this.costCostCategoriesUrl, this.selectedVendors, { headers: headers.append("authorization", `Bearer ${aadToken}`), params: { "service": "invoices" } })
        .pipe(
          tap(data => {

          }),
          catchError(err => this.handleHttpError(err))
        );
    }))
    
  }

  getInvoiceFilters(): Observable<invoiceFilter | WellError> {
    let apiURL = this.buService.attachBUParamtertoApiURL(this.costCostCategoriesUrl);
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.post<invoiceFilter>(apiURL, this.selectedWellApiData, { headers: headers.append("authorization", `Bearer ${aadToken}`), params: { "service": "invoicefilters" } })
        .pipe(
          tap(data => {
            this.invoicefilters = data;
          }),
          catchError(err => this.handleHttpError(err))
        );
    }))
    
  }

  getDataRefresh(): Observable<DataRefresh[] | WellError> {
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.get<DataRefresh[]>(this.costCostCategoriesUrl + "&service=dataRefresh", { headers: headers.append("authorization", `Bearer ${aadToken}`)})
        .pipe(
          tap(data => {
            this.dataRefresh = data;
          }),
          catchError(err => this.handleHttpError(err))
        );
    }))
    
  }

  getFavorites(): Observable<UserFavorite[] | WellError> {
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.get<UserFavorite[]>(this.costCostCategoriesUrl + "&service=favorites&CAI="+this.authService.cvxClaimsPrincipal.cai, { headers: headers.append("authorization", `Bearer ${aadToken}`)})
        .pipe(
          tap(),
          catchError(err => this.handleHttpError(err))
        );
    }))
  }

  addToFavorites(favorites: UserFavorite[]): Observable<UserFavorite[] | WellError> {
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.post<UserFavorite[]>(this.costCostCategoriesUrl + "&service=addFavorite", favorites, { headers: headers.append("authorization", `Bearer ${aadToken}`)})
        .pipe(
          tap(),
          catchError(err => this.handleHttpError(err))
        );
    }))
  }

  removeFavorites(favorites: UserFavorite[]): Observable<UserFavorite[] | WellError> {
    return this.getTokenHeader().pipe(concatMap(aadToken => {
      return this.http.post<UserFavorite[]>(this.costCostCategoriesUrl + "&service=deleteFavorite", favorites, { headers: headers.append("authorization", `Bearer ${aadToken}`)})
        .pipe(
          tap(),
          catchError(err => this.handleHttpError(err))
        );
    }))
  }

  private handleHttpError(error: HttpErrorResponse): Observable<WellError> {
    let dataError = new WellError();
    dataError.errorNumber = 100;
    dataError.message = error.statusText;
    dataError.friendlyMessage = 'An error occurred retrieving data.';
    return throwError(dataError);
  }
}
