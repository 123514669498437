import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotfoundComponent } from './components/notfound/notfound.component';
import { CostCompareComponent } from './components/cost-compare/cost-compare.component';
import { CostCategoriesComponent } from './components/cost-categories/cost-categories.component';
import { CompareWellsComponent } from './components/compare-wells/compare-wells.component';
import { AADGroupGuard } from './app-routing-guards';
import { LandingComponent } from './components/landing/landing.component';
import { UnauthorizedComponent } from './components/unauthorized/notfound/unauthorized.component';

const routes: Routes = [

  { path: '', component: LandingComponent },
  { path: 'cost-compare', component: CostCompareComponent, canActivate: [AADGroupGuard]},
  { path: 'cost-categories', component: CostCategoriesComponent},
  { path: 'compare-wells', component: CompareWellsComponent, canActivate: [AADGroupGuard]},
  { path: 'unauthorized', component: UnauthorizedComponent},
  { path: '**', component: NotfoundComponent},
];

//@NgModule({
//  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
//  exports: [RouterModule]
//})

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
